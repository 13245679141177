import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../styles.js';
import { useHistory } from 'react-router-dom';
import SoundfontTile from '../Components/SoundfontTile.js';

export default function ListOfSoundfonts(props) {
    let history = useHistory();
    const [signedIn, setSignedIn] = useState(undefined);
    const [search, setSearch] = useState("");

    useEffect(() => {
        fetch('/api/user').then(
            response => response.json()
        )
        .then(body => {
            setSignedIn(body.isSignedIn);
            }
        )
    })

    function addToCart(soundfont_id) {
        const opts = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }
        fetch('/api/add_to_cart/' + soundfont_id, opts).then(
            response => {
                if (response.ok) {
                    props.updatePaidFonts();
                }
            }
        )
    }

    return (
        <>
            <Container  className="glass fade-in-second">
            <Form>
                <br></br>
                <Form.Control placeholder="Search by name or color :)" value={search} onChange={(e) => setSearch(e.target.value)}></Form.Control>
                <br></br>
            </Form>
            </Container>
            <br/>
            <br/>
            {props.soundfonts.map((soundfont, id) => {
                    return (
                        <>
                        {soundfont.title.toLowerCase().includes(search.toLowerCase()) || soundfont.color.toLowerCase().includes(search.toLowerCase())
                        ?
                        <>
                        <Container  className="glass fade-in-third">
                        <Container style={{padding: "20px"}}>
                        <Container>
                            <Row>
                                <h1 className="glass-text">
                                    {soundfont.title}
                                </h1>
                            </Row>
                            <SoundfontTile description={soundfont.description} demo_url={soundfont.demo_url}></SoundfontTile>
                            {
                                props.isFree
                                ?
                                    <Row className="justify-content-center">
                                        <Button variant="success" href={soundfont.download_link} style={styles.gs_small_button}>Download</Button>
                                    </Row>
                                :
                                    signedIn
                                    ?
                                        soundfont.paid_for
                                        ?
                                            <Row className="justify-content-center">
                                                <Button variant="success" href={soundfont.download_link} style={styles.gs_small_button}>Download</Button>
                                            </Row>
                                        : soundfont.in_cart
                                            ?
                                            <Row className="justify-content-center">
                                                <Button onClick={() => history.push("/cart")} variant="success" style={styles.gs_small_button}>Added to cart!</Button>
                                            </Row>
                                            :
                                            <Row className="justify-content-center">
                                                <Button onClick={() => addToCart(soundfont.id)} variant="primary" style={styles.gs_small_button}>Add to cart (${soundfont.price})</Button>
                                            </Row>
                                    :
                                    <Row className="justify-content-center">
                                        <Button onClick={() => {history.push("/sign_in")}} variant="primary" style={styles.gs_small_button}>Buy for ${soundfont.price}</Button>
                                    </Row>
                            }
                        </Container>
                        </Container>
                        </Container>
                        <br/>
                        </>
                        :
                        <></>
                        }
                        
                        </>
                    )
                }
            )}
        </>
    )
}