import React from 'react';
import styles from '../styles.js';
import Container from 'react-bootstrap/Container';
import {Helmet} from 'react-helmet';
import Ratio from 'react-bootstrap/Ratio';

export default function FAQ() {
    const bg = process.env.PUBLIC_URL + '/planet_6.png';

    return (
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
            <Helmet>
                <title>GF | FAQ/Help</title>
            </Helmet>
            <Container className="fade-in-first" style={styles.gs_header_container}>
                <h1 style={styles.gs_header_underline}>
                    FAQ/Help
                </h1>
            </Container>
            <Container className="glass fade-in-second">
                <Container style={{padding: "20px"}}>
                    <Container>
                        <h3 className="glass-text" style={styles.gs_header_container}>
                            What is a soundfont?
                        </h3>
                        <p className="glass-text" style={styles.gs_sub_header_body}>
                            A soundfont is a collection of .wav files and code that can be uploaded to most modern lightsabers, allowing 
                            the sounds and blade animations to be customized. Basically, downloading a soundfont will allow you to make 
                            your lightsaber sound like mine does in its demo video (with limitations, depending on your soundboard). If you have 
                            a Proffie, it'll look like mine does in the videos as well!
                        </p>
                        <h3 className="glass-text" style={styles.gs_header_container}>
                            Can my lightsaber use Greyscale Fonts?
                        </h3>
                        <p className="glass-text" style={styles.gs_sub_header_body}>
                            As long as it's got a modern smoothswing-capable soundboard, it probably can! All soundfonts are available 
                            in the following formats:
                        </p>
                        <ul>
                            <li className="glass-text">
                                CFX
                            </li>
                            <li className="glass-text">
                                Proffie
                            </li>
                            <li className="glass-text">
                                Golden Harvest V3
                            </li>
                            <li className="glass-text">
                                Verso
                            </li>
                            <li className="glass-text">
                                Asteria
                            </li>
                            <li className="glass-text">
                                Xenopixel (versions 2 and 3)
                            </li>
                        </ul>
                        <br/>
                        <h3 className="glass-text" style={styles.gs_header_container}>
                            I don't have a lightsaber yet. Where should I get one?
                        </h3>
                        <p className="glass-text" style={styles.gs_sub_header_body}>
                            You won't find a better deal than <a href="https://sabertrio.com/" className="text-info" style={{textDecoration: 'none'}}>Sabertrio</a>. 
                            They make the best-sounding lightsabers on the market, their hilts are incredibly well-built, and their customer service is unparalleled. The 
                            downside is that they're constantly overwhelmed with orders (and you may have to wait for the one you want). If you aren't willing to wait for a Sabertrio, 
                            <a href="https://saberforge.com/" className="text-info" style={{textDecoration: 'none'}}> Saberforge</a> has a nice offering, 
                            <a href="https://www.starlight-sabers.com/" className="text-info" style={{textDecoration: 'none'}}> Starlight Sabers</a> has an awesome array of custom pieces 
                            alongside less-expensive hilts, and installers are always excellent options for more specific needs.
                        </p>
                        <br></br>
                        <h3 className="glass-text" style={styles.gs_header_container}>
                            Is my lightsaber going to look/sound exactly like it does in the soundfont's demo video?
                        </h3>
                        <p className="glass-text" style={styles.gs_sub_header_body}>
                            Every soundboard looks and sounds a little (or a lot) different. I 
                            showcase the <b>Proffie versions</b> of my soundfonts in demo videos, which are VERY visually interesting thanks to the tireless 
                            ingenuity of Greyscale's blade programmer, Kristian. Unfortunately, not all soundboards have the same capabilities. You'll 
                            have the best experience with Greyscale soundfonts if you have a Proffie or a CFX, since these are the most fully-featured 
                            soundboards (plus, Proffie versions get those neat blade styles!).
                        </p>
                        <br></br>
                        <h3 className="glass-text" style={styles.gs_header_container}>
                            Do you do custom work/commissions?
                        </h3>
                        <p className="glass-text" style={styles.gs_sub_header_body}>
                            Currently, I do not, sadly. I've offered them in the past, but the demand for custom soundfonts VASTLY exceeds my availability. Like, seriously, 
                            y'all would not believe how popular custom soundfonts are. I may offer them again someday, but for now, I can't do them. That said, 
                            if you have an idea for a soundfont, you can totally email me and pitch! If I end up using anything you send me (sounds, lore, etc.), you get 
                            the soundfont for free. Worst I can say is no ;)
                        </p>
                        <br></br> 
                        <h3 className="glass-text" style={styles.gs_header_container}>
                            How do I install these soundfonts? Sounds difficult and spooky.
                        </h3>
                        <p className="glass-text" style={styles.gs_sub_header_body}>
                            I hear you. Lightsabers are scary and complicated.<br/><br/>
                            Below are links to every owner's manual, resource, and tutorial video that I recommend. <b>You should read and watch everything relevant to your soundboard.</b> I
                            know, it's a lot, but learning this stuff is gonna make your life so much easier in the long run!<br/><br/>

                            <a href="https://www.plecterlabs.com/shop/article_info.php?articles_id=125" className="text-info" style={{textDecoration: 'none'}}>CFX Manual</a><br/>
                            <a href="https://fredrik.hubbe.net/lightsaber/proffieos.html" className="text-info" style={{textDecoration: 'none'}}>Proffie Manual</a><br/>
                            <a href="https://sabertec.net/wp-content/uploads/2021/07/Golden-Harvest-v3-Manual.pdf" className="text-info" style={{textDecoration: 'none'}}>Golden Harvest V3 Manual</a><br/>
                            <a href="https://darkwolfsabers.com/xenopixel-v2-support" className="text-info" style={{textDecoration: 'none'}}>Xenopixel V2 Manual and Support</a><br/>
                            <a href="https://darkwolfsabers.com/xeno3-pixel-a-rgb-support" className="text-info" style={{textDecoration: 'none'}}>Xeno3 Manual and Support</a><br/>
                            <a href="https://docs.google.com/document/d/1087sJ1SJPEaijbZi2Q-Uw6GQsTv9_dJa4nF6p7LmqbE/edit?pli=1" className="text-info" style={{textDecoration: 'none'}}>Asteria Manual</a><br/>
                            <a href="https://thesaberarmory.com/pages/verso-soundboard-information" className="text-info" style={{textDecoration: 'none'}}>Verso Manual and Software</a><br/>
                        </p>
                        <p className="glass-text" style={styles.gs_sub_header_body}>
                            <a href="https://crucible.hubbe.net/c/proffieboards/6" className="text-info" style={{textDecoration: 'none'}}>The Rebel Armory</a> is a great place to ask for Proffie help.
                            The creator of the board hangs out on there and there are nearly endless resources for you to access.
                            <br/>
                            <a className="text-info" style={{textDecoration: 'none'}} href="https://discord.com/invite/uUT3S5p">The Discord Group</a> is a great resource for any and all configuration questions.
                        </p>
                        <p className="glass-text" style={styles.gs_sub_header_body}>
                            I've embedded my favorite tutorial for each soundboard below. <b>This is not an exhaustive list!</b> Typing "___ tutorial" into YouTube's fancy search bar should 
                            give you an infinite pool to scoop up knowledge from. If you're feeling stuck or afraid, watch some videos!
                        </p>
                        <h3 className="glass-text" style={styles.gs_sub_header_body}>
                            Here's a good CFX tutorial (from one of my favorite guys!):
                        </h3>
                        <Ratio aspectRatio={'16x9'}>
                            <iframe src="https://www.youtube.com/embed/w6y4qZFNtqA" title="cfx_tutorial" allowFullScreen></iframe>
                        </Ratio>
                        <br/>
                        <h3 className="glass-text" style={styles.gs_sub_header_body}>
                            Here's a great Proffie tutorial (from a cool guy also named Sam!):
                        </h3>
                        <Ratio aspectRatio={'16x9'}>
                            <iframe src="https://www.youtube.com/embed/BrsjuaSuRIM" title="proffie_tutorial" allowFullScreen></iframe>
                        </Ratio>
                        <br/>
                        <h3 className="glass-text" style={styles.gs_sub_header_body}>
                            Here's a nice tutorial for GHv3 (from Carter, the GHv3 God):
                        </h3>
                        <Ratio aspectRatio={'16x9'}>
                            <iframe src="https://www.youtube.com/embed/kpdF7EardtI" title="ghv3_tutorial" allowFullScreen></iframe>
                        </Ratio>
                        <br/>                        
                        <h3 className="glass-text" style={styles.gs_sub_header_body}>
                            Here's a good intro to Xenopixel configuration:
                        </h3>
                        <Ratio aspectRatio={'16x9'}>
                            <iframe src="https://www.youtube.com/embed/cuXszuPVevo" title="xenopixel_tutorial" allowFullScreen></iframe>
                        </Ratio>
                        <br/>
                        <h3 className="glass-text" style={styles.gs_sub_header_body}>
                            Here's a good look at Asteria programming:
                        </h3>
                        <Ratio aspectRatio={'16x9'}>
                            <iframe src="https://www.youtube.com/embed/10E64AI2zus" title="asteria_tutorial" allowFullScreen></iframe>
                        </Ratio>
                        <br/>
                        <h3 className="glass-text" style={styles.gs_sub_header_body}>
                            Here's a thorough tutorial for Verso:
                        </h3>
                        <Ratio aspectRatio={'16x9'}>
                            <iframe src="https://www.youtube.com/embed/NpQMtnlFZtM" title="verso_tutorial" allowFullScreen></iframe>
                        </Ratio>
                        <br/>
                    </Container>
                </Container>
            </Container>
        </div>
    )
}