import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Ratio from 'react-bootstrap/Ratio';
import Button from 'react-bootstrap/Button';
import { useCookies } from "react-cookie";

export default function ListOfSoundfonts(props) {

    const [expanded, setExpanded] = useState(false);
    const [cookies, removeCookie] = useCookies(["cookieConsent"]);

    function resetCookiePreferences() {
        removeCookie("cookieConsent");
        const opts = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }
        fetch('/api/set_cookie_consent/' + 99, opts)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    console.log(response);
                }
            })
            .catch(error => {
                console.error(error);
        })
    }

    return (
        <Row>
            {cookies.cookieConsent == true && <Col sm={6} style={{ padding: '20px' }}>
                <Ratio aspectRatio={'16x9'}>
                    <iframe src={props.demo_url} title="demo" allowFullScreen></iframe>
                </Ratio>
            </Col>}
            {((cookies.cookieConsent == null || cookies.cookieConsent == "undefined") || cookies.cookieConsent == false) && <Col sm={6} style={{ padding: '20px' }}>
            <h3 className='glass-text text-danger'>{"<Soundfont demo video goes here>"}</h3>
            <p className="glass-text"><i>You'll have to accept all cookies to view soundfont demo videos (they're embedded YouTube videos, and they use cookies that I can't block)</i></p>
            <Button variant="light" onClick={() => resetCookiePreferences()}>Open Cookie Consent Banner</Button>
            </Col>}
            <Col sm={6} style={{ padding: '20px' }}>
                {/* Put everything before the first instance of an ellipsis (...) and put it in an h3 tag */}
                <h3 className="glass-text">
                    {props.description.split(/\u2026(.*)/s)[0] + '…'}
                </h3>
                {/* Put everything after the first ellipsis in a series of p tags, delimited by the & symbol */}
                {expanded ?
                    (props.description.split(/\u2026(.*)/s)[1]).split("&").map((blurb) => {return <p className="glass-text">{blurb}</p>})
                    :
                    <p className="glass-text">{(props.description.split(/\u2026(.*)/s)[1]).split("&")[0] + '..'}</p>
                }
                <b><a class="text" onClick={() => setExpanded(!expanded)}>{expanded ? "HIDE FULL CHAPTER" : "SHOW FULL CHAPTER"}</a></b>
            </Col>
        </Row>
    )

}