import React from 'react';
import styles from '../styles.js';
import Container from 'react-bootstrap/Container';
import {Helmet} from 'react-helmet';

export default function About() {
    const bg = process.env.PUBLIC_URL + '/planet_3.png';

    return (
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
            <Helmet>
                <title>GF | About</title>
            </Helmet>
            <Container>
                <h1 className="fade-in-first" style={styles.gs_header_underline}>
                    About
                </h1>
            </Container>
            <Container className="glass fade-in-second">
                <Container style={{padding: "20px"}}>
                <h4 className="glass-text" style={{paddingTop: "10px"}}>
                        My name is Sam!
                    </h4>
                    <p className="glass-text">
                        ... and I run Greyscale Fonts with the mission statement of <b>creating high quality modern 
                        smoothswing lightsaber soundfonts</b> and distributing them here! <b>I 
                        also work with a talented Proffie bladestyle programmer, <a class="text-info" href="https://twitch.tv/KrisCrunch" style={{textDecoration: 'none'}}>
                        Kristian</a>, who makes those gorgeous blade animations seen in the videos!</b> We hope you enjoy these soundfonts as much as we do.
                    </p>
                    <br/>
                    <h4 className="glass-text">
                        None of my soundfonts are strictly Jedi or Sith.
                    </h4>
                    <p className="glass-text">
                        ... and are meant to pull power from both sides of the Force.
                        This design philosophy lives right in the name: Greyscale. Neither light nor dark, but always extra crispity. My soundfonts are meant to be enjoyed with a variety of colors, blade profiles, and effects.
                    </p>                    
                    <br />
                    <p className="glass-text">
                        In addition to the <a className="text-info" style={{textDecoration: 'none'}} href="https://1drv.ms/u/s!Ak7rEkVDcGzxjLByaFcrC4DiRzrbOg?e=I9sOm8">Proffie styles</a> that Kristian cooks up in-house, <a href="https://www.crystalfocus.net/grafx-bladeprofiles" className="text-info" style={{textDecoration: 'none'}}>CrystalFocus.net</a> also builds CFX-compatible styles for my soundfonts that are uniquely 
                        their own! If you're lucky enough to have a CFX in your lightsaber, check those out!
                    </p>
                    <br />
                    <p className="glass-text">
                        My logo was designed by Alex Neubauer. When she has an online portfolio, I'll link it here.
                    </p>
                    <p className="glass-text">
                        All of the website art was designed by <a className="text-info" style={{textDecoration: 'none'}} href="https://www.etsy.com/shop/JinnandTonic?ref=simple-shop-header-name&listing_id=943247926">JinnAndTonic</a>. If 
                        you need any sort of custom digital artwork, he's your guy.
                    </p>
                    <br/>
                    <h4 className="glass-text">
                        I'm all over the internet!
                    </h4>
                    <p className="glass-text">
                        Follow me wherever you're active: <br/>
                        <a className="text-info" style={{textDecoration: 'none'}} href="https://www.youtube.com/c/GreyscaleFonts">YouTube</a> | 
                        <a className="text-info" style={{textDecoration: 'none'}} href="https://www.instagram.com/greyscalefonts/"> Instagram</a> | 
                        <a className="text-info" style={{textDecoration: 'none'}} href="https://www.facebook.com/greyscalefonts/"> Facebook</a> | 
                        <a className="text-info" style={{textDecoration: 'none'}} href="https://www.tiktok.com/@greyscalefonts"> TikTok</a><br/>
                    </p>
                    <br/>
                    <h4 className="glass-text">
                        I often make these soundfonts live on YouTube!
                    </h4>
                    <p className="glass-text">
                        If you want to contribute ideas, offer your opinion, or just hang out/chat while I work, be sure to watch for livestreams on my <a className="text-info" style={{textDecoration: 'none'}} href="https://www.youtube.com/c/GreyscaleFonts">YouTube</a> channel!
                        I keep my favorite segments in <a className="text-info" style={{textDecoration: 'none'}} href="https://www.youtube.com/playlist?list=PLQ_BO-LQfwEFl8pLuUyiObnTZkMV6M23V">this playlist</a>, if you're curious about how some of these were made.
                    </p>      
                </Container>
            </Container>
            <br />
            <Container className="glass fade-in-second">
                <Container style={{padding: "20px"}}>
                <h3>
                    Contact me!
                </h3>
                <br/>
                <h5 className="glass-text">
                    ... but before you email or otherwise message me, please be aware that...
                </h5>
                <ol className="glass-text">
                    <li>
                        <b>I'm a little slow to respond sometimes.</b><br/>
                        I usually take <b>1-3 days</b> to respond. This is a hobby for me, and my life outside of Greyscale is often a hurricane of other stuff; I don't always get the chance to immediately respond to every email I get. Thanks for being patient!
                    </li>
                    <br/>
                    <li>
                        <b>I'm not a lightsaber technical expert.</b><br/>
                        If you need help making a soundfont work on your lightsaber, I probably won't be the best resource. I'm always happy to help, but I'll be slower and less knowledgeable than some of your other options.<br/><br/>
                        <b>Before you contact me about a configuration question or technical issue, please:</b>
                        <ul>
                            <li>
                                <b>Read the owner's manual for your soundboard</b> (a lot of the questions I get can be answered just by ctrl-f/cmd-f searching the owner's manual!)
                            </li>
                            <li>
                                <b>Find and watch some tutorials for your soundboard</b> (start with those listed in my FAQ, but don't be afraid to search YouTube for the question you have!)
                            </li>
                            <li>
                                <b>Google the question/issue you have</b> (the odds that you're the first person to have this question are very near zero! Lots of Google answers can also be found on the <a className="text-info" style={{textDecoration: 'none'}} href="https://www.reddit.com/r/lightsabers/">Lightsabers subreddit</a>.)
                            </li>
                            <li>
                                <b>If it's a configuration question (i.e. "how do I make my lightsaber do ____?" or "how do I change/edit ____?"), start by asking the <a className="text-info" style={{textDecoration: 'none'}} href="https://discord.com/invite/uUT3S5p">Discord Group</a></b> (lots of experts and experienced programmers hang out there!)
                            </li>
                            <li>
                                <b>Read my <a className="text-info" style={{textDecoration: 'none'}} href="proffie_is_hard">FAQ</a> and <a className="text-info" style={{textDecoration: 'none'}} href="readme">Readme</a></b>.
                            </li>
                        </ul>
                    </li>
                    <br/>
                    <li>
                        <b>It's ultimately your responsibility to learn how your soundboard works.</b><br/>
                        I love you and I want you to have a brain-alteringly amazing experience with your lightsaber, but there are so many of you, and only one of me.<br/>
                        If I can't find the answer to your question on the internet, the only help I can offer is general debugging advice. We might reach a point where I don't know how to help, and you might have to ask for help elsewhere. It's rare, but it happens. Expect that I can probably help, but it's not a guarantee.<br/><br/>
                    </li>
                </ol>
                <p className="glass-text">
                    <b>Sam's Email</b>: sam@greyscalefonts.com
                </p>
                <p className="glass-text">
                    <b>Kristian's Email:</b> kristian@greyscalefonts.com
                </p>
                <i>
                    Email Kristian, the author of Greyscale's Proffie bladestyles, for Proffie help. Please be extra appreciative of Kristian, who has very generously offered to help answer emails out of the kindness of his oversized heart.
                </i>
                </Container>
            </Container>
        </div>
    )
}