import React, { useEffect, useState } from 'react';
import styles from '../styles.js';
import Container from 'react-bootstrap/Container';
import {Helmet} from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ListOfSoundfonts from '../Components/ListOfSoundfonts';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Account() {
    const [email, setEmail] = useState("");
    const [paidSoundfonts, setPaidSoundfontState] = useState([]);

    useEffect(() => {
        getUserInfo();
        getOwnedFonts();
    }, [])

    function getUserInfo() {
        fetch('/api/get_user_info').then(
            response => {
                if (response.ok) {
                    return response.json();
                }
            }
        ).then(
            data => {
                setEmail(data.email);          
            }
        );
    }

    function getOwnedFonts() {
        fetch('/api/paid_fonts').then(
            response => {
                if (response.ok) {
                    return response.json()
                }
            }
        ).then(
            data => {
                let ownedFonts = [];
                for (const font of data) {
                    if (font.paid_for) {
                        ownedFonts.push(font)
                    }
                }
                setPaidSoundfontState(ownedFonts);
            }
        )
    }

    function validate() {
        return email.length > 0;
    }

    async function handleSubmit(e) {
        const opts = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }
        await fetch('/api/set_user_email?email=' + email, opts)
            .then(response => {
                if (response.ok) {
                    toast.success("Email updated!",
                    {position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined});
                }
                else {
                    toast.error("Something went wrong - try a different email and let me know if that works!",
                    {position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined});
                }
            }
        )
    }

    const bg = process.env.PUBLIC_URL + '/cart_bg.png';

    return (
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
            <Helmet>
                <title>GF | Account</title>
            </Helmet>
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
            <Container className="fade-in-first" style={styles.gs_header_container}>
                <h1 style={styles.gs_header_underline} >
                    Account
                </h1>
            </Container>
            <Container className="glass fade-in-second" style={{width: "50%", padding: "20px"}}>
                <Form>
                    <Form.Group size="lg" controlId="email">
                        <Form.Label>
                            Email
                        </Form.Label>
                        <Form.Control
                            type="email"
                            defaultValue={email}
                            onChange={(e) => setEmail(e.target.value)}/>
                    </Form.Group>
                    <br></br>
                    <Button style={styles.gs_small_button} variant="success" disabled={!validate()} onClick={() => handleSubmit()}>
                        Update Email
                    </Button>                
                </Form>
            </Container>
            <br></br>
            <br></br>
            <Container className="fade-in-first" style={styles.gs_header_container}>
                <h1 style={styles.gs_header_underline} >
                    My Soundfonts
                </h1>
            </Container>
            <ListOfSoundfonts soundfonts={paidSoundfonts} is_free={false} updatePaidFonts={getOwnedFonts}/>
        </div>
    )
}