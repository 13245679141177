const styles = {
  gs_medium_button: {
    width: '300px',
    margin: '10px'
  },
  gs_small_button: {
    width: '200px',
    margin: '10px'
  },
  gs_tiny_button: {
    width: '120px',
    margin: '10px'
  },
  gs_auth_button: {
    width: '120px',
    marginLeft: '5px',
    marginRight: '5px'
  },
  gs_header_container: {
    padding: '8px'
  },
  gs_header_underline: {
    borderBottom: '1.5px solid white',
    paddingBottom: '15px'
  },
  gs_center_stuff: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  gs_sub_header_body: {
    paddingLeft: '8px'
  }
};

export default styles;