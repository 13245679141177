import React, {useState, useEffect} from 'react';
import ListOfSoundfonts from '../Components/ListOfSoundfonts';
import {Helmet} from 'react-helmet';
import Container from 'react-bootstrap/esm/Container';
import styles from '../styles.js';

export default function PaidSoundfonts() {
    const [paidSoundfonts, setPaidSoundfontState] = useState([]);

    useEffect(
        () => {
            updatePaidFonts();
        }, [])

    function updatePaidFonts() {
        fetch('/api/paid_fonts').then(
            response => {
                if (response.ok) {
                    return response.json()
                }
            }
        ).then(
            data => {
                setPaidSoundfontState(data);
            }
        )
    }

    const bg = process.env.PUBLIC_URL + '/planet_5.png';

    return (
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
            <Helmet>
                <title>GF | Extra Crispity Soundfonts</title>
            </Helmet>
            <Container className="fade-in-first" style={styles.gs_header_container}>
                <h1 style={styles.gs_header_underline}>
                    Extra Crispity Soundfonts
                </h1>
                <i className="glass-text">
                    Note: demo videos feature the Proffie bladestyle. <b>If your lightsaber doesn't have a Proffie soundboard, it 
                    won't look the same.</b>
                </i>
                <br/>
                <i className="glass-text">
                    All soundfonts come in <b>CFX, Proffie, Golden Harvest v3, Verso, Asteria, and Xenopixel!</b> Be sure to read the manual 
                    for your soundboard to understand its features and limitations.
                </i>
            </Container>
            <ListOfSoundfonts soundfonts={paidSoundfonts} is_free={false} updatePaidFonts={updatePaidFonts}/>
        </div>
    )
}