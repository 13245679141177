import React, {useState} from 'react';
import styles from '../styles.js';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import {Helmet} from 'react-helmet';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SignIn() {
    const [email, setEmail] = useState("");

    function validate() {
        return email.length > 0;
    }

    async function handleSubmit(e) {
        const opts = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }
        await fetch('/api/send_login_email?email=' + email, opts)
            .then(response => {
                if (response.ok) {
                    toast.success("An email is on the way!",
                    {position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined});
                }
                else {
                    toast.error("Something went wrong, sorry about that! Go yell at me via email (sam@greyscalefonts.com)!",
                    {position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined});
                }
            })
    }

    const bg = process.env.PUBLIC_URL + '/planet_2.png';

    return (
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
            <Helmet>
                <title>GF | Sign In</title>
            </Helmet>
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
            <Container className="fade-in-first" style={styles.gs_header_container}>
                <h1 style={styles.gs_header_underline} >
                    Sign In
                </h1>
            </Container>
            <br></br>
            <Container className="glass fade-in-second">
                <Container style={{padding: "20px"}}>
                    <h4>
                        Passwords are dumb and hard to remember! 
                    </h4>
                    <i>
                        Enter your email down there and you'll receive a link you can follow to sign in, password-free.
                    </i>
                    <br></br>
                    <br></br>
                    <i>
                        <b>Requesting a sign-in email will create an account for you</b> if you don't have an account yet!
                    </i>
                    <br></br>
                    <i>
                        Email is <b>only</b> used for account association and soundfont delivery (no spam/"marketing"/whatever else from me, I hate that stuff!).
                    </i>
                    <br></br>
                    <br></br>
                <Form>
                    <Form.Group size="lg" controlId="email">
                        <Form.Label>
                            Email
                        </Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}/>
                    </Form.Group>
                    <br></br>
                    <Button style={styles.gs_small_button} disabled={!validate()} onClick={() => handleSubmit()}>
                        Send Login Email
                    </Button>
                </Form>
            </Container>
            </Container>
        </div>
    )
}