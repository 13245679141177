import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useCookies, setCookie } from "react-cookie";
import styles from '../styles.js';

export default function CookieBanner() {
    const [cookies, setCookie] = useCookies(["cookieConsent"]);

    function setConsent(consent) {
        setCookie("cookieConsent", consent == 1)
        const opts = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }
        fetch('/api/set_cookie_consent/' + consent, opts)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    console.log(response);
                }
            })
            .catch(error => {
                console.error(error);
        })
    }

    return (
        <Container style={styles.gs_header_container}>
            <i className='glass-text'>
            This site uses cookies. Embedded YouTube videos come with third-party cookies, 
            and I use cookies to verify and remember that you're signed in.
            Learn more and update your preferences anytime <a className="text-info" style={{textDecoration: 'none'}} href="/cookies">on the Cookie Policy screen</a>.
            </i>
            <br></br>
            <Button style={styles.gs_small_button} onClick={() => setConsent(1)}>Accept All</Button>
            <Button style={styles.gs_small_button} variant="dark" onClick={() => setConsent(0)}>Accept Necessary</Button>
        </Container>
    )
}