import React, { useState, useEffect } from 'react';
import styles from '../styles.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Helmet} from 'react-helmet';

export default function Resources() {
    const [resources, setResources] = useState([]);

    useEffect(
        () => {
            updateResources();
        }, [])

    function updateResources() {
        fetch('/api/resources').then(
            response => {
                if (response.ok) {
                    return response.json()
                }
            }
        ).then(
            data => {
                setResources(data);
            }
        )
    }

    const bg = process.env.PUBLIC_URL + '/planet_1.png';

    return (
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
            <Helmet>
                <title>GF | Resources</title>
            </Helmet>
            <Container className="fade-in-first" style={styles.gs_header_container}>
                <h1 style={styles.gs_header_underline} >
                    Resources
                </h1>
            </Container>
        {resources.map((resource, index) => {
            return (
                <Container className="glass fade-in-second">
                <Container style={{padding: "20px"}}>
                <h3 className="glass-text" style={styles.gs_header_container}>
                    {resource.title}
                </h3>
                <Container>
                <Row style={styles.gs_center_stuff}>
                    <Col sm={6}>
                        <p className="glass-text">
                            {resource.description}
                        </p>
                    </Col>
                    <Col sm={6} className="text-center">
                    <Button btn_variant="info" btn_style={styles.gs_medium_button} href={resource.link}>{resource.button_label}</Button>
                    </Col>
                </Row>
            </Container>
            </Container>
            </Container>
            )
        })}
        </div>
    )
}