import React from 'react';
import Container from 'react-bootstrap/Container';

function Footer() {
    return (
        <Container style={{textAlign: "center", padding: "10px"}}>
            <pre><a class="text-info" href="/privacy" style={{textDecoration: 'none'}}>Privacy Policy</a>   <a class="text-info" href="/terms" style={{textDecoration: 'none'}}>Terms & Conditions</a>   <a class="text-info" href="/cookies" style={{textDecoration: 'none'}}>Cookie Policy</a></pre>
            <i>Copyright Greyscale Fonts LLC ({new Date().getFullYear()})</i>
        </Container>
    )
}

export default Footer;