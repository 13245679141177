import React, {useState, useEffect} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import styles from '../styles.js';
import { useHistory } from 'react-router-dom';

export default function GSNavbar(props) {

    const [previousScrollPosition, setpreviousScrollPosition] = useState(0);
    const [visible, setVisible] = useState(true);
    const { location } = props;
    const [signedIn, setSignedIn] = useState(undefined);

    let history = useHistory();

    const handleScroll = () => {
        const currentScrollPosition = window.pageYOffset;
        setVisible((previousScrollPosition - currentScrollPosition > 1) || currentScrollPosition < 10);
        setpreviousScrollPosition(currentScrollPosition);
    }

    async function signOut() {
        fetch('/api/logout').then(
            response => {
                if (response.ok) {
                    setSignedIn(false)
                    return response.json()
                }
            }
        ).then(
            history.push("/home")
        )
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [previousScrollPosition, visible, handleScroll]);

    useEffect(() => {
        fetch('/api/user').then(
            response => response.json()
        )
        .then(body => {
            setSignedIn(body.isSignedIn);
            }
        )
    }, [location])

    const navbarItems = [
        {title: 'Home', route: '/home'},
        {title: 'Free Soundfonts', route: '/free_stuff'},
        {title: 'Extra Crispity Soundfonts', route: '/the_good_stuff'},
        {title: 'Resources', route: '/other_stuff'},
        {title: 'About', route: '/about'},
        {title: 'FAQ/Help', route: '/proffie_is_hard'},
        {title: 'Readme', route: '/readme'},
        {title: 'Orbit\'s Keep', route: '/lore_for_nerds'}
    ];

    let auth_block = <></>;
    if (signedIn !== undefined) {
        if (signedIn) {
            auth_block = 
            <>
                <Button style={styles.gs_auth_button} variant="success" onClick={() => history.push("/cart")}>Checkout</Button>
                <Button style={styles.gs_auth_button} variant="info" onClick={() => history.push("/account")}>Account</Button>  
                <Button style={styles.gs_auth_button} variant="primary" onClick={() => signOut()}>Sign Out</Button>                                     
            </>
        }
        else {
            auth_block = <Button href="/sign_in" style={styles.gs_auth_button} variant="info">Sign In</Button>
        }
    }

    return (
        <Navbar expand="lg" variant="light" bg="secondary" style={{position: 'sticky', top: visible ? 0 : -80, zIndex: 5, transition: 'top 0.6s'}}>
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto" activeKey={location.pathname}>
                {navbarItems.map((item, index) => {
                        return (
                            <Nav.Link href={item.route} key={item.route} style={{paddingLeft: '15px', paddingRight: '15px'}}>
                                {item.title}
                            </Nav.Link>
                        )
                    })}
                </Nav>
                {auth_block}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}