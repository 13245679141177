import React from 'react';
import styles from '../styles.js';
import Container from 'react-bootstrap/Container';
import {Helmet} from 'react-helmet';

export default function Readme() {

    const bg = process.env.PUBLIC_URL + '/cart_bg.png';

    return (
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
            <Helmet>
                <title>GF | Readme</title>
            </Helmet>
            <Container className="fade-in-first" style={styles.gs_header_container}>
                <h1 style={styles.gs_header_underline} >
                    Readme
                </h1>
            </Container>
            <Container className="glass fade-in-second">
                <Container style={{padding: "20px"}}>
                <i className="glass-text">
                    <b>Soundfonts are packages of sound files optimized for modern lightsber soundboards</b>. I don't sell lightsabers, just 
                    the sounds. Blade code for Proffie soundboards is bundled for free with all soundfonts, and the code files for all soundfonts are available 
                    in the <a href="/other_stuff" className="text-info" style={{textDecoration: 'none'}}>Resources</a> tab.
                </i>
                <br></br>
                <br></br>
                <i className="glass-text">
                    All soundfonts come in <b>CFX, Proffie, Golden Harvest v3, Verso, Asteria, and Xenopixel.</b> Be sure to read the manual 
                    for your soundboard to understand its features and limitations. Not every soundboard has every feature; your experience may 
                    differ from what is shown in the demo video, depending on the capabilities of your lightsaber.
                </i>
                <br></br>
                <br></br>
                <i className="glass-text">
                    <b>If your lightsaber doesn't have a Proffie soundboard, the 
                        blade animations will look different than they do in the demo videos.</b>
                </i>
                <br></br>
                <br></br>
                <i className="glass-text">
                    After purchase, soundfonts will be unlocked for your account and <b>available to download in the Account tab, and links will be emailed 
                    to your account's email </b>(if your account doesn't have an email address yet, you can add one by providing one during sign-in, or by 
                    entering one in the Account tab).
                </i>
                <br></br>
                <br></br>
                <i className="glass-text">
                <b>It's ultimately your responsibility to learn how your soundboard works and solve technical issues.</b><br/>
                    Bugs and unexpected behaviors are common; lightsabers are complicated and this is a relatively niche hobby.<br/>
                    I am happy to help with issues you're having with Greyscale soundfonts, but we might reach a point where I don't know how to help. Expect that I will probably be able to help, but it's not a guarantee.
                </i>
                </Container>                
            </Container>
        </div>
    )
}