import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import styles from '../styles.js';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function CartItems(props) {
    function removeFromCart(soundfont_id) {
        const opts = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }
        fetch('/api/remove_from_cart/' + soundfont_id, opts).then(
            response => {
                if (response.ok) {
                    props.updateCartItems();
                }
            }
        )
    }

    return (
        <>
            {
            props.cartItems.length > 0
            ?
            props.cartItems.map((item, index) => {
                    return (
                        <>
                        <Container  className="glass fade-in-second">
                        <Container style={{padding: "10px"}}>
                        <Container>
                            <Row>
                                <Col sm={6} style={{ padding: '10px' }}>
                                    <h4>
                                        ${item.price} | <i>{item.title}</i>
                                    </h4>
                                </Col>
                                <Col sm={6} style={{ padding: '10px' }}>
                                    <Button onClick={() => removeFromCart(item.id)} variant="danger" style={styles.gs_small_button}>Remove from Cart</Button>
                                </Col>
                            </Row>                           
                        </Container>
                        </Container>
                        </Container>
                        <br/>
                        </>
                    )
                }
            )
            :
            <Container  className="glass fade-in-second">
                <Container style={{padding: "20px"}}>
                    <i>Nothing to see here! Go add some stuff and report back.</i>
                </Container>
            </Container>
        }
        </>
    )
}