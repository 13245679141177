import React, {useState} from 'react';
import styles from '../styles.js';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import {Helmet} from 'react-helmet';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';

export default function SignIn() {
    const [username, setUsername] = useState("");
    const [tempPassword, setTempPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    let history = useHistory();

    function validate() {
        return newPassword.length > 0 && username.length > 0;
    }

    async function handleSubmit(e) {
        const opts = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }
        await fetch('/api/set_new_password?username=' + username + '&temp_password=' + tempPassword + '&new_password=' + newPassword, opts)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    console.log(response);
                }
            })
            .then(
                data => history.push("/home")
            )
            .catch(error => {
                console.error(error);
        })
    }

    const bg = process.env.PUBLIC_URL + '/planet_2.png';

    return (
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
            <Helmet>
                <title>GF | Reset Password</title>
            </Helmet>
            <Container className="fade-in-first" style={styles.gs_header_container}>
                <h1 style={styles.gs_header_underline} >
                    Reset Password
                </h1>
            </Container>
            <br></br>
            <Container className="glass fade-in-second">
                <Container style={{padding: "20px"}}>
            <Form>
                <Form.Group size="lg" controlId="email">
                    <Form.Label>
                        Username
                    </Form.Label>
                    <Form.Control
                        type="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}/>
                </Form.Group>
                <br></br>
                <Form.Group size="lg" controlId="email">
                    <Form.Label>
                        Temporary Password
                    </Form.Label>
                    <Form.Control
                        type="password"
                        value={tempPassword}
                        onChange={(e) => setTempPassword(e.target.value)}/>
                </Form.Group>
                <br></br>
                <Form.Group size="lg" controlId="email">
                    <Form.Label>
                        New Password
                    </Form.Label>
                    <Form.Control
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}/>
                </Form.Group>
                <br></br>
                <Button style={styles.gs_auth_button} disabled={!validate()} onClick={() => handleSubmit()}>
                    Sign In
                </Button>
            </Form>
            <br></br>
            </Container>
            </Container>
        </div>
    )
}