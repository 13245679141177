import React, {useEffect} from 'react';
import FreeSoundfonts from './Pages/FreeSoundfonts.js';
import PaidSoundfonts from './Pages/PaidSoundfonts.js';
import Resources from './Pages/Resources.js';
import Home from './Pages/Home.js';
import About from './Pages/About.js';
import FAQ from './Pages/FAQ.js';
import SignIn from './Pages/SignIn.js'
import Cart from './Pages/Cart.js';
import ResetPassword from './Pages/ResetPassword.js';
import GSNavbar from './Components/GSNavbar';
import Readme from './Pages/Readme.js';
import Account from './Pages/Account.js';
import CookieBanner from './Components/CookieBanner.js';
import Footer from './Components/Footer.js';
import PrivacyPolicy from './Pages/PrivacyPolicy.js'
import './custom.scss';
import {BrowserRouter as Router, Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Journals from './Pages/Journals.js';
import {useCookies} from 'react-cookie';
import TermsAndConditions from './Pages/TermsAndConditions.js';
import CookiePolicy from './Pages/CookiePolicy.js';

export default function App() {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const NavbarWithRouter = withRouter(GSNavbar);

  useEffect(() => {
    getUserCookieConsent();
}, [])

  function getUserCookieConsent() {
    if (cookies.cookieConsent == null || cookies.cookieConsent == "undefined") {
      fetch('api/cookie_consent').then(
          response => {
              if (response.ok) {
                  return response.json();
              }
          }
      ).then(
          data => {
            switch (data.consent) {
              case 0:
                setCookie("cookieConsent", false);
                break;
              case 1:
                setCookie("cookieConsent", true);
                break;
            }          
          }
      )
    }
  }

  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>Greyscale Fonts</title>
        </Helmet>
          <NavbarWithRouter/>
          {(cookies.cookieConsent == null || cookies.cookieConsent == "undefined") && <CookieBanner></CookieBanner>}
          <Switch>
            <Route path="/free_stuff" component={FreeSoundfonts} />
            <Route path="/the_good_stuff" component={PaidSoundfonts} />
            <Route path="/other_stuff" component={Resources} />
            <Route path="/about" component={About} />
            <Route path="/proffie_is_hard" component={FAQ} />
            <Route path="/sign_in" component={SignIn} />
            <Route path="/cart" component={Cart} />
            <Route path="/reset_password" component={ResetPassword} />
            <Route path="/readme" component={Readme} />
            <Route path="/account" component={Account} />
            <Route path="/lore_for_nerds" component={Journals} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/terms" component={TermsAndConditions} />
            <Route path="/cookies" component={CookiePolicy} />
            <Route path="/" component={Home} />
          </Switch>
          <Footer></Footer>
      </div>
    </Router>
  );
}