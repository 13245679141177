import React, { useEffect, useState } from 'react';
import styles from '../styles.js';
import Container from 'react-bootstrap/Container';
import {Helmet} from 'react-helmet';
import CartItems from '../Components/CartItems.js';
import Button from 'react-bootstrap/Button';

export default function Cart() {
    const Paddle = window.Paddle;
    
    const [cartItems, setCartItems] = useState([]);
    const [totalCost, setTotalCost] = useState(0.0);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [items, setItems] = useState([]);
    const [readTC, setReadTC] = useState(false);

    useEffect(() => {
        updateCartItems();
        getUserInfo();
    }, [])

    function getUserInfo() {
        fetch('api/get_user_info').then(
            response => {
                if (response.ok) {
                    return response.json();
                }
            }
        ).then(
            data => {
                setUsername(data.username);
                setEmail(data.email)
            }
        )
    }

    function updateCartItems() {
        fetch('/api/get_cart').then(
            response => {
                if (response.ok) {
                    return response.json();
                }
            }
        ).then(
            data => {
                if (data) {
                    setCartItems(data);
                    let total = 0.0;
                    let tempItems = [];
                    data.forEach(element => {
                        total += element.price;
                        tempItems.push({
                            priceId: element.paddle_id,
                            quantity: 1
                        })
                    });
                    setTotalCost(total);
                    setItems(tempItems);
                }
                else {
                    setCartItems([]);
                    setTotalCost(0.0);
                }                
            }
        )
    }

    function initPaddleCheckout() {
        Paddle.Checkout.open({
            settings: {
                displayMode: "overlay",
                theme: "dark",
                locale: "en",
                showAddDiscounts: true
            },
            items: items,
            customData: { 'cart_items': cartAsListOfIds(), 'username': username },
            customer: {
                email: email
            }
        })
    }

    function cartAsListOfIds() {
        let cart = "";
        cartItems.forEach(item => {
            cart += item.id + ';';
        })
        return cart;
    }

    const bg = process.env.PUBLIC_URL + '/cart_bg.png';

    return (
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
            <Helmet>
                <title>GF | Cart</title>
            </Helmet>
            <Container className="fade-in-first" style={styles.gs_header_container}>
                <h1 style={styles.gs_header_underline} >
                    Cart
                </h1>
            </Container>
            <CartItems cartItems={cartItems} updateCartItems={updateCartItems}/>
            {
            cartItems.length > 0
            ?
            <Container className="fade-in-second glass text-center">
                    <Container style={{padding: "10px"}}>
                        <h1 className='glass-text'>Total: ${totalCost}</h1>
                    </Container>
                <Button onClick={() => initPaddleCheckout()} disabled={!readTC} variant="success" style={styles.gs_small_button}>Paddle Checkout</Button>
                <br></br>
                <input id="tc_checkbox" type="checkbox" onClick={() => setReadTC(!readTC)}/>
                <label className='glass-text inline-label-text'>I've read and agree to the <a href="/terms" className="text-info" style={{textDecoration: 'none'}}>Terms and Conditions</a> and the <a href="/readme" className="text-info" style={{textDecoration: 'none'}}>Readme</a>!</label>
            </Container>
            :
            <></>
            }
        </div>
    )
}