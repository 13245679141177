import React, { useState, useEffect } from 'react';
import styles from '../styles.js';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import {Helmet} from 'react-helmet';
import Pagination from 'react-bootstrap/Pagination';

export default function Journals() {

    const [journals, setJournals] = useState([]);
    let entries = [];

    const [active, setActive] = useState(1);

    for (let number = 1; number <= journals.length; number++) {
        entries.push(
          <Pagination.Item key={number} active={number === active} onClick={() => setActive(number)}>
            {number}
          </Pagination.Item>,
        );
    }

    useEffect(
        () => {
            updateJournals();
        }, [])

    function updateJournals() {
        fetch('/api/journals').then(
            response => {
                if (response.ok) {
                    return response.json()
                }
            }
        ).then(
            data => {
                setJournals(data);
            }
        )
    }

    const bg = process.env.PUBLIC_URL + '/solar_system.png';

    return (    
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
        <Helmet>
            <title>GF | Orbit's Keep</title>
        </Helmet>        
            <Container className="fade-in-first" style={styles.gs_header_container}>
                <h1 style={styles.gs_header_underline} >
                    Visions From Orbit's Keep
                </h1>
            </Container>

            <Container className="glass fade-in-second">
                <Container style={{padding: "20px"}}>
                <h4 className="glass-text" style={{paddingTop: "10px"}}>
                        At this exact moment, in a solar system trillions of lightyears away...
                    </h4>
                    <p className="glass-text">
                        ... the sudden introduction of The Force has escalated local tensions to an interplanetary conflict. 
                        Nearly two hundred years ago, a mysterious deity known as The Oracle appeared to the inhabitants of 
                        Orbit's Keep, a small solar system with several planets capable of sustaining life. The Oracle claimed 
                        to have a connection to an ancient power that allowed It to move objects with Its mind, perform 
                        extraordinary feats of physical strength, heal the sick, and even travel beyond the stars in an instant. 
                        More interestingly, The Oracle also claimed to be able to bestow these powers on those that It deemed
                        righteous enough to weild them. Known to us as The Force, this power was given a new name in Orbit's Keep:
                        The Grace of The Oracle.
                    </p>
                    <p className="glass-text">
                        Life forms across Orbit's Keep were selectively given The Grace, and what began as a miracle quickly turned 
                        to disaster. A once-peaceful and isolated solar system has turned to ceaseless war, with no end in sight.
                    </p>
                    <p className="glass-text">
                        In early 2023, a flood of reports of peculiar visions have come forward from fellow Jedi, Sith, and Grey users 
                        of The Force alike. These visions appear to be journal entries of sorts from inhabitants of Orbit's Keep. One 
                        person in particular, known as Lillian, gives the most consistent and detailed accounts of the events as they 
                        happen, so much of what we know is from her perspective.
                    </p>
                    <p className="glass-text">
                        Below is a map of Orbit's Keep, and below that are the accounts of visions as they are translated to writing. 
                        Please allow time for translation; collecting stories from Force users around the galaxy is a lot of effort.
                    </p>
                    <Button variant="primary" href="https://1drv.ms/u/s!Ak7rEkVDcGzxj_J2mD-e0JSgwWJvQA?e=QlGmNA" style={styles.gs_medium_button}>Map of Orbit's Keep</Button>
                </Container>
            </Container>
        <Container className="glass fade-in-second">
            <Container style={{padding: "20px"}}>
                <div>
                    <Pagination>{entries}</Pagination>
                </div>
            </Container>
        </Container>
        {journals.map((journal) => {
            return (
            <> {journal.list_index === active ? 
                <Container className="glass fade-in-second">
                    <Container style={{padding: "10px"}}>
                    <h4 className="glass-text" style={styles.gs_header_container}>
                        {journal.author} | {journal.date_time}
                    </h4>
                    <Container style={{padding: "20px"}}>
                        {journal.content.split("&").map((blurb) => {return <p className="glass-text">{blurb}</p>})}
                    </Container>
                </Container>
                </Container>
            : <></>}
            </>                
            )
        })}
        <Container className="glass fade-in-second">
            <Container style={{padding: "20px"}}>
                <div>
                    <Pagination>{entries}</Pagination>
                </div>
            </Container>
        </Container>
    </div>
    )
}