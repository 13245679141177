import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import styles from '../styles.js';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';

export default function Home() {

    let history = useHistory();

    const bg = process.env.PUBLIC_URL + '/solar_system.png';

    useEffect(() => {
        signInIfCodePresent();
    }, [window.location.search])

    async function signInIfCodePresent() {
        const opts = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        }
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const email = params.get('email');
        const login_code = params.get('login_code');
        const success = params.get('success');
        if (email && success) {
            toast.success("Signed in as " + email + "!",
                    {position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined});
        }
        else if (email && login_code) {
            await fetch('/api/login?email=' + email + '&login_code=' + login_code, opts)
            .then(response => {
                if (response.ok) {
                    history.push("/home?email=" + email + "&success=true")
                }
                else {
                    toast.error("Something went wrong, sorry about that! Go yell at me via email (sam@greyscalefonts.com)!",
                    {position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined});
                }
            })
        }
        return;
    }

    return (
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
            <div style={{height: '100%'}}>
                <Container className="fade-in-first" style={{paddingTop: "50px"}}>
                    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="greyscale fonts logo" width="100%" height="30.8%" />
                </Container>
                <br></br>
                <br></br>
                <Container className="fade-in-second">
                    <Row xs='auto' className="justify-content-center">
                        <Col>
                            <Button variant="primary" href="/free_stuff" style={styles.gs_small_button}>Free Soundfonts</Button>
                        </Col>
                        <Col>
                        <Button variant="primary" href="/the_good_stuff" style={styles.gs_medium_button}>Extra Crispity Soundfonts</Button>
                        </Col>
                        <Col>
                        <Button variant="primary" href="/other_stuff" style={styles.gs_small_button}>Resources</Button>
                        </Col>
                    </Row>
                </Container>
                <Container className="fade-in-third">
                    <Row xs='auto' className="justify-content-center">
                        <Col className="justify-content-center">
                            <Button variant="info" href="about" style={styles.gs_small_button}>About</Button>
                        </Col>
                        <Col className="justify-content-center">
                            <Button variant="light" href="lore_for_nerds" style={styles.gs_small_button}>Orbit's Keep</Button>
                        </Col>
                        <Col className="justify-content-center">
                            <Button variant="info" href="readme" style={styles.gs_small_button}>Readme</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}