import React, {useState, useEffect} from 'react';
import ListOfSoundfonts from '../Components/ListOfSoundfonts';
import {Helmet} from 'react-helmet';
import Container from 'react-bootstrap/esm/Container';
import styles from '../styles.js';

export default function FreeSoundfonts() {

    const [freeSoundfonts, setFreeSoundfontsState] = useState([]);

    useEffect(
        () => {
            fetch('/api/downloads').then(
                response => {
                    if (response.ok) {
                        return response.json()
                    }
                }
            ).then(
                data => setFreeSoundfontsState(data)
            )
        }, []
    )

    const bg = process.env.PUBLIC_URL + '/planet_4.png';

    return (
        <div className="responsive-background" style={{backgroundImage: "url(" + bg + ")"}}>
            <Helmet>
                <title>GF | Free Soundfonts</title>
            </Helmet>
            <Container className="fade-in-first" style={styles.gs_header_container}>
                <h1 style={styles.gs_header_underline}>
                    Free Soundfonts
                </h1>
                <i className="glass-text">
                    Note: demo videos feature the Proffie bladestyle. <b>If your lightsaber doesn't have a Proffie soundboard, it 
                    won't look the same.</b>
                </i>
                <br/>
                <i className="glass-text">
                    All soundfonts come in <b>CFX, Proffie, Golden Harvest v3, Verso, Asteria, and Xenopixel!</b> Be sure to read the manual 
                    for your soundboard to understand its features and limitations.
                </i>
            </Container>
            <ListOfSoundfonts soundfonts={freeSoundfonts} isFree={true}/>
        </div>
    )
}